<template>
  <div v-if="isVisible" @click.self="closeModal" class="fixed top-0 left-0 h-full w-full z-50 flex flex-column items-center justify-center" style="background-color: rgba(0,0,0,.2);">
    <div class="w-full sm:w-1/2 lg:w-2/5 bg-white rounded-lg mx-6">

      <div class="rg-modal-header" style="background-color: white;">
        <span>{{title}}</span>

        <a href="javascript: void(0);" class="rg-modal-close-btn" @click="closeModal"><i class="fa fa-times" style="color: black;"></i></a>
      </div>

      <div class="p-4">
        <div class="text-center font-bold text-lg mt-2">
                    {{title}}
                     
          </div>
          <div class="text-center text-sm mb-4 pt-2" style="font-weight: normal;">
                    {{message}} 
          </div>


      </div>

      <div class="bg-gray-100 rounded-b-lg px-6 py-4 mt-5 flex justify-center">
        <!-- Cancel Button -->
        <button type="button" class="w-full text-white v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small" style="background-color: rgb(36, 119, 246); border-color: rgb(36, 119, 246); width: 20%;">
          <span class="v-btn__content" @click="closeModal">
             OK
          </span>
        </button>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: 'PlanUpdateModal',
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
/* Custom styles can be added here if needed */
</style>
